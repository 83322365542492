function Throttler(handlerFunc, mswait) {
    this.isBusy = false
    this.reqWhileBusy = false
    this.param = undefined

    this.queue = (param) => {
        if(param){
            this.param = param
        }
        if (!this.isBusy) {
            
            handlerFunc(this.param)
            this.isBusy = true
            setTimeout(
                that => { 
                    that.isBusy = false
                    if (that.reqWhileBusy) { 
                        that.queue()
                        that.reqWhileBusy = false
                    }
                },
                mswait,
                this)
        } else {
            this.reqWhileBusy = true
        }
    }
}

export default Throttler
