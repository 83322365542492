import SidebarService from "./sidebarService"
import Throttler from "./throttler"

export default function resizeListener() {

    let throttled = new Throttler(SidebarService.close, 100)

    window.addEventListener('resize', (ev) => {
        if (window.innerWidth > 800) {
            throttled.queue()
        }
    })
}