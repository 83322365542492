function _SidebarService() {

    this.isSbOpen = false
    this.sb = null
    this.hbc = null
    this.hbe = null
    this.hbx = null


    this.init = () => {
        this.sb = document.getElementById("buttons-container")
        this.hbc = document.getElementById("hb-container")
        this.hbe = document.getElementById("hb-show")
        this.hbx = document.getElementById("hb-hide")
        this.dl = document.getElementById("dark-layer")
    }

    this.open = ()=>{
        this.sb.style.display = "flex"
        this.sb.classList.remove("closed")
        this.sb.classList.add("open")
        this.hbx.style.display = "inline"
        this.hbe.style.display = "none"
        this.dl.style.display = "block"
        this.isSbOpen = true
    }

    this.close = ()=>{
        this.sb.style.display = ""
        this.sb.classList.remove("open")
        this.sb.classList.add("closed")
        this.hbx.style.display = "none"
        this.hbe.style.display = ""
        this.dl.style.display = ""
        this.isSbOpen = false
    }

    this.manageClick = (ev) => {
        if (ev.target == this.hbc && !this.isSbOpen) {
            this.open()
        } else {
            this.close()
        }
    }
}


export default new _SidebarService()
