function _FormService() {

    this.form = null


    this.init = () => {
        /*let forms = document.getElementsByTagName("form")
        if (!forms || forms.length != 1) {
            console.log("no form")
            return
        }
        this.form = forms[0]
        this.form.addEventListener("submit", (ev) => {
            ev.preventDefault()

            let elems = this.form.elements
            let datats = {}
            for (let i = 0; i < elems.length; i++) {
                datats[elems[i].name] = elems[i].value
            }

            let url = "https://wevmit01.azurewebsites.net/formtest.cshtml"

            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify(datats)
            }).then(ev => { console.log(ev) })

        })*/
    }
}

export default new _FormService()