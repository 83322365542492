import resizeListener from "./utils/resizeListener"
import SidebarService from "./utils/sidebarService"
import FormService from "./utils/formService"

let globals = {
    jsran: false
}

document.addEventListener("readystatechange", ev => {
    if (document.readyState === "interactive" || (document.readyState === "complete" && !globals.jsran)) {
        globals.jsran = true
        SidebarService.init()
        FormService.init()
        resizeListener()

        window.addEventListener("click", (ev) => {
            SidebarService.manageClick(ev)
        })
    }
})